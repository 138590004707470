import React, { useMemo, useState } from "react";
import styles from "./TrafficChannel.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  ReferenceLine,
} from "recharts";
import useDarkMode from "../../../../hooks/useDarkMode";
import { useGetRequestPerPeriodQuery } from "../../../../api/reg-service.api";
import { TPeriod } from "../../../../api/types/get-requests-per-period";
import useLimits from "../../../../hooks/useLimits";

const intervals = [
  "Hours", // by hour
  "Days", // by day
  "Months", // by month
];

interface IProps {
  className?: string;
}

const mapOptionToPeriod: Record<string, TPeriod> = {
  Hours: "hour",
  Days: "day",
  Months: "month",
};

const mapPeriodToOption: Record<TPeriod, string> = {
  hour: "Hours",
  day: "Days",
  month: "Months",
  minute: "Minutes",
};

const formatterMap: Record<TPeriod, (date: string) => string | number> = {
  hour: (date) => new Date(date).getHours(),
  day: (date) =>
    new Date(date).toLocaleDateString("en", {
      month: "short",
      day: "2-digit",
    }),
  minute: (date) => new Date(date).getMinutes(),
  month: (date) =>
    new Date(date).toLocaleDateString("en", {
      month: "short",
    }),
};

const TrafficChannel: React.FC<IProps> = ({ className }) => {
  const { dayLimit, monthLimit } = useLimits();
  const darkMode = useDarkMode(true, { storageKey: "" });
  // const [sorting, setSorting] = useState(intervals[0]);
  const [period, setPeriod] = useState<TPeriod>("hour");
  const { data: requestPerPeriod, isLoading: isLoadingrequestsPerPeriod } =
    useGetRequestPerPeriodQuery(
      {
        period,
      },
      {
        pollingInterval: 60000,
      }
    );

  const limit = useMemo(() => {
    let _limit;
    switch (period) {
      case "day":
        _limit = dayLimit;
        break;
      case "hour":
        _limit = 0;
        break;
      case "month":
        _limit = monthLimit;
        break;
    }

    return _limit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestPerPeriod]);

  const data = useMemo(() => {
    if (!requestPerPeriod) return [];
    if (!limit) {
      return [
        ...requestPerPeriod.map((el) => ({
          name: formatterMap[period](el.date),
          requests: el.requests,
        })),
      ].reverse();
    }
    return [
      ...requestPerPeriod.map((el) => {
        return {
          name: formatterMap[period](el.date),
          requests:
            el.requests > limit
              ? el.requests - (el.requests - limit)
              : el.requests,
          yourLimit: el.requests > limit ? el.requests : undefined,
          limit,
        };
      }),
    ].reverse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestPerPeriod, limit]);

  const legend =
    !data.length && !isLoadingrequestsPerPeriod
      ? []
      : [
          {
            title: "Under-limit requests",
            color: "#007AFF",
          },
          {
            title: `Over-limit requests`,
            color: "#FF3B30",
          },
        ];
  return (
    <Card
      className={cn(styles.card, className)}
      title="RPC Requests Overview"
      classTitle="title-purple"
      head={
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={mapPeriodToOption[period]}
          setValue={(value) => {
            const nextPeriod = mapOptionToPeriod[value];
            if (nextPeriod) {
              setPeriod(nextPeriod);
            }
          }}
          options={intervals}
          small
        />
      }
    >
      <div className={styles.chart}>
        {!data.length && !isLoadingrequestsPerPeriod ? (
          <>
            <img
              className={styles.stub}
              alt="stub"
              src="/images/content/stub-1.svg"
            />
            <div className={cn(styles.titleStub)}>No data available</div>
          </>
        ) : null}
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            barSize={46}
            barGap={8}
          >
            <CartesianGrid
              strokeDasharray="none"
              stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
              vertical={false}
            />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
              padding={{ left: 10 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "#272B30",
                borderColor: "rgba(255, 255, 255, 0.12)",
                borderRadius: 8,
                boxShadow:
                  "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
              }}
              labelStyle={{ fontSize: 12, fontWeight: "500", color: "#fff" }}
              itemStyle={{
                padding: 0,
                textTransform: "capitalize",
                fontSize: 12,
                fontWeight: "600",
                color: "#fff",
              }}
              cursor={{ fill: "#f3f2f3" }}
            />
            <Bar dataKey="requests" stackId="a" fill="#007AFF" />
            <Bar dataKey="yourLimit" stackId="a" fill="#FF3B30" />
            {limit && (
              <ReferenceLine
                y={limit}
                stroke="#FF3B30"
                strokeDasharray="5 5"
                ifOverflow="extendDomain"
                strokeWidth={2}
              />
            )}
            {/* <Bar dataKey="market" stackId="a" fill="#B1E5FC" /> */}
            {/* <Bar dataKey="social media" stackId="a" fill="#CABDFF" /> */}
            {/* <Bar dataKey="other" stackId="a" fill="#FFD88D" /> */}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.legend}>
        {legend.map((x, index) => (
          <div className={styles.indicator} key={index}>
            <div
              className={styles.color}
              style={{ backgroundColor: x.color }}
            ></div>
            {x.title}
          </div>
        ))}
      </div>
    </Card>
  );
};

export default TrafficChannel;
