import { injectIntl } from "react-intl";
import formatMessage from "../../utils/formatMessage";
import { Typography } from "antd";

import "./soon.page.sass";
import { IBaseComponentProps } from "../../types";

const SOON_TITLE = {
  id: "soon.title",
};
const SOON_MESSAGE = {
  id: "soon.message",
};

interface IProps extends IBaseComponentProps {}

const Soon: React.FC<IProps> = ({ intl }) => {
  return (
    <div className="soon">
      <div className="soon__inner">
        <Typography.Title level={1}>
          {formatMessage(intl, SOON_TITLE)}
        </Typography.Title>
        <Typography>{formatMessage(intl, SOON_MESSAGE)}</Typography>
      </div>
    </div>
  );
};

export default injectIntl(Soon);
