import useToken from "antd/es/theme/useToken";

import "./pricing-plans.page.sass";
import {
  useGetCurrentPlanQuery,
  useGetCurrentSubscriptionQuery,
} from "../../api/reg-service.api";
import Dashboard, { HOME_PAGE_PRICING_PLANS } from "../../components/Dashboard";
import Title from "../../components/Title";

const PricingPlans: React.FC = () => {
  const [, token] = useToken();

  const currentPlanQuery = useGetCurrentPlanQuery((() => {})(), {
    pollingInterval: 60000,
  });
  const currentSubscriptionQuery = useGetCurrentSubscriptionQuery(
    (() => {})(),
    {
      pollingInterval: 60000,
    }
  );
  return (
    <div
      style={{
        color: token.colorPrimary,
      }}
      className="home-page"
    >
      <Title className="home-page__title" text={HOME_PAGE_PRICING_PLANS} />
      <Dashboard
        currentPlanQuery={currentPlanQuery}
        currentSubscriptionQuery={currentSubscriptionQuery}
      />
    </div>
  );
};

export default PricingPlans;
