import { createApi } from "@reduxjs/toolkit/query/react";
import { ILoginRequest, ILoginResponse } from "./types/login";
import {
  IFinalizeRegisterRequest,
  IRegisterRequest,
  TFinalizeRegisterResponse,
  TRegisterResponse,
} from "./types/register";
import customBaseQuery from "./customBaseQuery";
import { removeToken, saveLandingId, saveToken } from "../utils/utils";
import { IGetMeRequest, IGetMeResponse } from "./types/get-me";
import {
  IGetRequesterRequest,
  IGetRequesterResponse,
} from "./types/get-requester";
import {
  IGetCurrentPlanRequest,
  IGetCurrentPlanResponse,
  TGetPlanByIdRequest,
  TGetPlanByIdResponse,
  TGetPlansRequest,
  TGetPlansResponse,
} from "./types/get-current-plan";
import { ISetEmailRequest } from "./types/set-email";
import {
  IFinalizeSetPasswordREquest,
  ISetPasswordRequest,
} from "./types/set-password";
import { TDeleteApiKeyRequest } from "./types/deleteApiKey";
import { TPaymentMethodResponse } from "./types/paymentMethod";
import { TPaymentTokenResponse } from "./types/paymentToken";
import notificationView from "../utils/notification";
import { TError } from "./types/error";
import { IProfileRequest, IProfileResponse } from "./types/profile";
import {
  ICreatePaymentIntentRequest,
  ICreatePaymentIntentResponse,
} from "./types/payment-intent";
import {
  IGetPriceItemRequest,
  IGetPriceItemResponse,
} from "./types/price-item";
import { IGetGoogleDocResponse, TGetGoogleDocRequest } from "./types/googleDoc";
import {
  IGetCurrentSubscriptionRequest,
  IGetCurrentSubscriptionResponse,
} from "./types/get-subscription";
import {
  IGetTransactionIdRequest,
  IGetTransactionIdResponse,
} from "./types/transactionId";
import {
  IGetDiscountCodesByLandingIdRequest,
  TGetDiscountCodesByLandingIdResponse,
} from "./types/discountCodes";
import {
  IGetRequestsPerPeriodRequest,
  TGetRequestsPerPeriodResponse,
} from "./types/get-requests-per-period";

const API_NAME = "regServiceApi";

export const regServiceApi = createApi({
  reducerPath: "regServiceApi",
  tagTypes: ["apiKey", "user", "priceItem"],
  baseQuery: customBaseQuery({
    baseUrl: "/api",
    apiName: API_NAME,
  }),
  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: (data) => {
        return {
          url: "/auth/login",
          method: "POST",
          data,
        };
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          if (data.accessToken && data.refreshToken) {
            saveToken(data);
          }
          dispatch(regServiceApi.endpoints.getMe.initiate());
        } catch (error) {
          const {
            error: {
              data: { message },
            },
          } = error as TError;
          notificationView("error", message);
        }
      },
    }),
    register: builder.mutation<TRegisterResponse, IRegisterRequest>({
      query: (data) => ({
        url: "/auth/initiate-register",
        method: "POST",
        data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (error) {
          const {
            error: {
              data: { message },
            },
          } = error as TError;
          notificationView("error", message);
        }
      },
    }),
    finalizeregister: builder.mutation<
      TFinalizeRegisterResponse,
      IFinalizeRegisterRequest
    >({
      query: (data) => ({
        url: "/auth/finalize-register",
        method: "POST",
        data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          window.gtag &&
            window.gtag("event", "registration", {
              // Consistent event name formatting
              plan_name: "Beginner", // Plan name
              page: window.location.pathname, // Page URL path
              plan_price: 0, // Plan price (number format)
              time: new Date().toISOString(), // Current time in ISO format
            });
        } catch (error) {}
      },
    }),
    getMe: builder.query<IGetMeResponse, IGetMeRequest>({
      // TODO: rename to check session
      query: () => ({
        url: "/users/me",
        method: "GET",
      }),
      providesTags: ["user"],
    }),
    getProfile: builder.query<IProfileResponse, IProfileRequest>({
      query: () => ({
        url: "/users/profile",
        method: "GET",
      }),
      transformResponse: (response: IProfileResponse) => {
        saveLandingId(response.landingId);
        return {
          ...response,
        };
      },
      providesTags: ["user"],
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: "/auth/logout",
        method: "GET",
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          removeToken();
          dispatch(regServiceApi.util.resetApiState());
        } catch (err) {
          removeToken();
          dispatch(regServiceApi.util.resetApiState());
        }
      },
    }),
    getRequester: builder.query<IGetRequesterResponse, IGetRequesterRequest>({
      query: () => ({
        url: "/users/requester",
        method: "GET",
      }),
    }),
    getCurrentPlan: builder.query<
      IGetCurrentPlanResponse,
      IGetCurrentPlanRequest
    >({
      query: () => ({
        url: "/plans/current",
        method: "GET",
      }),
    }),
    setEmail: builder.mutation<void, ISetEmailRequest>({
      query: (data) => ({
        url: "/users/initiate-set-email",
        method: "POST",
        data,
      }),
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          // dispatch(regServiceApi.endpoints.logout.initiate());
        } catch (error) {
          const {
            error: {
              data: { message },
            },
          } = error as TError;
          notificationView("error", message);
        }
      },
    }),
    finalizeSetUserEmail: builder.mutation<
      TFinalizeRegisterResponse,
      IFinalizeSetPasswordREquest
    >({
      query: (data) => ({
        url: "/users/finalize-set-email",
        method: "POST",
        data,
      }),
    }),
    setPassword: builder.mutation<void, ISetPasswordRequest>({
      query: (data) => ({
        url: "/users/password",
        method: "POST",
        data,
      }),
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(regServiceApi.endpoints.logout.initiate());
        } catch (error) {
          const {
            error: {
              data: { message },
            },
          } = error as TError;
          notificationView("error", message);
        }
      },
    }),
    getPlans: builder.mutation<TGetPlansResponse, TGetPlansRequest>({
      query: (data) => ({
        url: "/plans/plans",
        method: "POST",
        data,
      }),
    }),
    getPlanById: builder.mutation<TGetPlanByIdResponse, TGetPlanByIdRequest>({
      query: (data) => ({
        url: "/plans/plan-by-id",
        method: "POST",
        data,
      }),
    }),
    getApiKeys: builder.query<string[], void>({
      query: () => ({
        url: "/api-keys",
        method: "GET",
      }),
      providesTags: ["apiKey"],
    }),
    createApiKey: builder.mutation<string, void>({
      query: () => ({
        url: "/api-keys",
        method: "POST",
      }),
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (error) {
          const {
            error: {
              data: { message },
            },
          } = error as TError;
          notificationView("error", message);
        }
      },
      invalidatesTags: ["apiKey"],
    }),
    deleteApiKey: builder.mutation<string, TDeleteApiKeyRequest>({
      query: (data) => ({
        url: "/api-keys",
        method: "DELETE",
        data,
      }),
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (error) {
          const {
            error: {
              data: { message },
            },
          } = error as TError;
          notificationView("error", message);
        }
      },
      invalidatesTags: ["apiKey"],
    }),
    getPaymentMethods: builder.query<TPaymentMethodResponse, void>({
      query: () => ({
        url: "/payments/methods",
        method: "GET",
      }),
    }),
    getPaymentTokens: builder.query<TPaymentTokenResponse, void>({
      query: () => ({
        url: "/payments/token-addresses",
        method: "GET",
      }),
    }),
    createPaymentIntent: builder.mutation<
      ICreatePaymentIntentResponse,
      ICreatePaymentIntentRequest
    >({
      query: (data) => ({
        url: "/payments/payment-intent",
        method: "POST",
        data,
      }),
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          const {
            error: {
              data: { message },
            },
          } = error as TError;
          notificationView("error", message);
        }
      },
    }),
    getPriceItem: builder.query<IGetPriceItemResponse, IGetPriceItemRequest>({
      query: (data) => ({
        url: "/payments/price-item",
        method: "POST",
        data,
      }),
      providesTags: ["priceItem"],
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          const {
            error: {
              data: { message },
            },
          } = error as TError;
          notificationView("error", message);
        }
      },
    }),
    getTransactionId: builder.mutation<
      IGetTransactionIdResponse,
      IGetTransactionIdRequest
    >({
      query: () => ({
        url: "/payments/transaction-id",
        method: "GET",
      }),
    }),
    getDiscountCodesByLandingId: builder.query<
      TGetDiscountCodesByLandingIdResponse,
      IGetDiscountCodesByLandingIdRequest
    >({
      query: (data) => ({
        url: "/discount-codes/by-landing-id",
        method: "POST",
        data,
      }),
    }),
    getPrivacyPolicy: builder.query<
      IGetGoogleDocResponse,
      TGetGoogleDocRequest
    >({
      query: () => ({
        url: "/google-doc/privacy-policy",
        method: "GET",
      }),
    }),
    getTerms: builder.query<IGetGoogleDocResponse, TGetGoogleDocRequest>({
      query: () => ({
        url: "/google-doc/terms",
        method: "GET",
      }),
    }),
    getCurrentSubscription: builder.query<
      IGetCurrentSubscriptionResponse,
      IGetCurrentSubscriptionRequest
    >({
      query: () => ({
        url: "/subscriptions/current",
        method: "GET",
      }),
    }),
    cancelSubscription: builder.mutation<void, void>({
      query: () => ({
        url: "/subscriptions/cancel",
        method: "POST",
      }),
    }),

    getRequestPerPeriod: builder.query<
      TGetRequestsPerPeriodResponse,
      IGetRequestsPerPeriodRequest
    >({
      query: (data) => ({
        url: `/requests/per-period?period=${data.period}`,
        method: "GET",
      }),
    }),
  }),
});

export const selectUser = regServiceApi.endpoints.getMe.select();

export const {
  useLoginMutation,
  useRegisterMutation,
  useGetMeQuery,
  useGetRequesterQuery,
  useGetCurrentPlanQuery,
  useGetPlansMutation,
  useGetPlanByIdMutation,
  useGetPaymentMethodsQuery,
  useGetPaymentTokensQuery,
  useGetApiKeysQuery,
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
  useSetPasswordMutation,
  useSetEmailMutation,
  useLogoutMutation,
  useFinalizeregisterMutation,
  useFinalizeSetUserEmailMutation,
  useGetProfileQuery,
  useCreatePaymentIntentMutation,
  useGetPriceItemQuery,
  useGetPrivacyPolicyQuery,
  useGetTermsQuery,
  useCancelSubscriptionMutation,
  useGetCurrentSubscriptionQuery,
  useGetTransactionIdMutation,
  useGetDiscountCodesByLandingIdQuery,
  useGetRequestPerPeriodQuery,
} = regServiceApi;
