import React, { PropsWithChildren, useState } from "react";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { Outlet } from "react-router-dom";
import withUnautorizedRedirect from "../../../hocs/withUnautorizedRedirect";

interface IProps {
  wide?: boolean;
}

const Page: React.FC<PropsWithChildren<IProps>> = ({ wide }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={styles.page}>
        <Sidebar
          className={cn(styles.sidebar, {
            [styles.visible]: visible,
          })}
          onClose={() => setVisible(false)}
        />
        <Header onOpen={() => setVisible(true)} />
        <div className={styles.inner}>
          <div
            className={cn(styles.container, {
              [styles.wide]: wide,
            })}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default withUnautorizedRedirect(Page);
