import { FC } from "react";
import { TPlan } from "../../entities/plan";
import { convertBigNumber, convertBigNumberToNumber } from "../../utils/convertBigNumberToNumber";
import { useGetPaymentTokensQuery } from "../../api/reg-service.api";
import formatMessage from "../../utils/formatMessage";
import { IBaseComponentProps } from "../../types";
import {
  PLANS_LIST_DISCOUNT,
  PLANS_LIST_DISCOUNT_FIRST,
  PLANS_LIST_TAX,
  PLANS_LIST_PLANCARD_MONTH
} from "../PlansList";
import { injectIntl } from "react-intl";


type TProps = {
  selectedPlan: TPlan
} & IBaseComponentProps;

const StandartPlanRenderPrice: FC<TProps> = ({ selectedPlan, intl, }) => {
  const { data: tokens } = useGetPaymentTokensQuery();
  const paymentToken = tokens ? tokens[0] : undefined;

  return (
    <>
      {selectedPlan?.discountPercentage ? (
        <div className="plans-list__price">
          <span className="plans-list__discount_price">
            {convertBigNumberToNumber(
              selectedPlan?.price,
              paymentToken?.decimals
            )}
            $
          </span>
          <span className="plans-list__price_additionally">
            {selectedPlan?.discountPercentage
              ? convertBigNumber(
                selectedPlan?.price,
                paymentToken?.decimals
              )
                .times(1 - selectedPlan?.discountPercentage / 100)
                .integerValue()
                .toString()
              : null}
            $
          </span>
          <span className="plans-list__part">
            {" "}
            /{formatMessage(intl, PLANS_LIST_PLANCARD_MONTH)}
          </span>
        </div>
      ) : (
        <div className="plans-list__price">
          <span className="plans-list__price_additionally">
            {convertBigNumberToNumber(
              selectedPlan?.price,
              paymentToken?.decimals
            )}
            $
          </span>
        </div>
      )}
      <div className="plans-list__price_hint">
        {selectedPlan?.discountPercentage
          ? selectedPlan?.name === "HybridN225"
            ? formatMessage(intl, {
              id: PLANS_LIST_DISCOUNT.id,
              params: { months: 1 },
            })
            : formatMessage(intl, {
              id: PLANS_LIST_DISCOUNT_FIRST.id,
              params: {
                months:
                  selectedPlan?.name === "HybridN1500" ? 6 : 3,
              },
            })
          : formatMessage(intl, PLANS_LIST_TAX)}
      </div>
    </>
  )
}

export default injectIntl(StandartPlanRenderPrice);