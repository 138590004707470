import React, { FC, useState } from "react";
import "./Dashboard.sass";
import { Typography } from "antd";
import formatMessage from "../../utils/formatMessage";
import { injectIntl } from "react-intl";
import {
  regServiceApi,
  useCancelSubscriptionMutation,
  useGetDiscountCodesByLandingIdQuery,
  useGetPaymentTokensQuery,
  useGetTransactionIdMutation,
} from "../../api/reg-service.api";
import { IBaseComponentProps } from "../../types";
import { TPlan } from "../../entities/plan";
import CheckoutModal from "../CheckoutModal";
import DashboardInformationWindow from "../DashboardInformationWindow";
import useQueryWithPolling from "../../hooks/useQueryWithPolling";
import SubscriptionSettingsModal from "../SubscriptionSettingsModal";
import SubscriptionDetails from "../SubscriptionDetails";
import useSubscriptionDetails from "../../hooks/useSubscriptionDetails";
import { TSubscription } from "../../entities/subscription";
import {
  PLAN_TS_STORAGE_KEY,
  SUBSCRIPTION_TS_STORAGE_KEY,
} from "../../constants";
import { pick } from "ramda";
import useSubscriptionEdit from "../../hooks/useSubscriptionEdit";
import { useDispatch } from "react-redux";
import notificationView from "../../utils/notification";
import useGetPlanFromSubscriptions from "../../hooks/useGetPlanFromSubscriptions";
import useNextBilledPrice from "../../hooks/useNextBilledPrice";
import useGetLandingLinkByAuthorizedUser from "../../hooks/useGetLandingLinkByAuthorizedUser";
import { HOME_PAGE_MY_PLAN, HOME_PAGE_MY_PLAN_DESCRIPTION } from ".";
import useGetSelectedPlan from "../../hooks/useGetSelectedPlan";
import PlansList from "../PlansList";

type TProps = {
  currentPlanQuery: any;
  currentSubscriptionQuery: any;
} & IBaseComponentProps;
/* features here:
  - subscription (subscribe, cancel, update payment method, pay mow(same with))
*/
const Dashboard: FC<TProps> = ({
  intl,
  currentPlanQuery,
  currentSubscriptionQuery,
}) => {
  const {
    selectedPlan,
    selectedPaidTariffOption,
    setSelectedPaidTariffOption,
  } = useGetSelectedPlan();
  const { data: tokens } = useGetPaymentTokensQuery();
  const dispatch = useDispatch();

  const comparePlan = (data?: TPlan, prevData?: TPlan) => {
    return !!(
      data?.id !== prevData?.id || data?.validUntil !== prevData?.validUntil
    );
  };

  const { startPolling: startPollingCurrentPlan } = useQueryWithPolling(
    regServiceApi.endpoints.getCurrentPlan,
    PLAN_TS_STORAGE_KEY,
    pick(["id", "validUntil"]),
    comparePlan
  );

  const compareSubscription = (
    data: TSubscription,
    prevData: TSubscription
  ) => {
    return !!(
      data?.id !== prevData?.id ||
      data?.status !== prevData?.status ||
      data?.nextBilledAt !== prevData?.nextBilledAt
    );
  };

  const { startPolling: startPollingCurrentSubscription } = useQueryWithPolling(
    regServiceApi.endpoints.getCurrentSubscription,
    SUBSCRIPTION_TS_STORAGE_KEY,
    pick(["id", "status", "nextBilledAt"]),
    compareSubscription
  );

  const { currentPlanFromSubscription } = useGetPlanFromSubscriptions();

  const { landingId } = useGetLandingLinkByAuthorizedUser();

  const { data: discountCodes } = useGetDiscountCodesByLandingIdQuery({
    landingId,
  });

  const { subscription, badge, standardButtonProps } = useSubscriptionDetails(
    currentPlanQuery,
    currentSubscriptionQuery,
    selectedPlan?.name || "",
    currentPlanFromSubscription
  );

  const nextBilledPrice = useNextBilledPrice(
    currentPlanQuery,
    currentSubscriptionQuery,
    tokens,
    currentPlanFromSubscription
  );

  const { canEdit, disabledCancel, disabledUpdate } = useSubscriptionEdit(
    currentPlanQuery,
    currentSubscriptionQuery
  );

  const disableRetry = subscription?.status !== "past_due";

  const [cancelSubscription] = useCancelSubscriptionMutation();
  const [visible, setVisible] = useState(false);
  const [visibleModalPaymentUpdates, setVisibleModalPaymentUpdates] =
    useState(false);
  const [
    getTransactionId,
    { data: getTransactionIdData, isLoading: isGetTransactionIdLoading },
  ] = useGetTransactionIdMutation();
  const transactionId = getTransactionIdData?.transactionId;
  const [isShowSubscriptionSettingsModal, setIsShowSubscriptionSettingsModal] =
    useState(false);

  const showModal = () => {
    dispatch(regServiceApi.util.invalidateTags(["priceItem"]));
    setVisible(true);
  };

  const showSubscriptionSettingsModal = () => {
    setIsShowSubscriptionSettingsModal(true);
  };
  const checkoutModalComplete = () => {
    if (visibleModalPaymentUpdates) {
      notificationView(
        "info",
        "Your payment details have been successfully updated"
      );
      return;
    }
    startPollingCurrentPlan("creation");
    startPollingCurrentSubscription("creation");
  };

  return (
    <>
      {badge && (
        <DashboardInformationWindow
          className="dashboard__info_block"
          text={formatMessage(intl, badge.message)}
          type={badge.type}
        />
      )}
      <div style={{ minHeight: "144px" }}>
        {currentPlanQuery.data && subscription && (
          <SubscriptionDetails
            editPlanAction={showSubscriptionSettingsModal}
            subscription={subscription}
            nextBilledPrice={nextBilledPrice}
            canEdit={canEdit}
            plan={currentPlanQuery.data}
          />
        )}
      </div>
      <div className="dashboard__plans">
        <Typography className="dashboard_myplan_block_title">
          {formatMessage(intl, HOME_PAGE_MY_PLAN)}
        </Typography>
        <p className="dashboard_subtitle">
          {formatMessage(intl, HOME_PAGE_MY_PLAN_DESCRIPTION)}
        </p>
        {standardButtonProps &&
          <PlansList
            standardButtonProps={standardButtonProps}
            subscription={subscription}
            selectedPaidTariffOption={selectedPaidTariffOption}
            setSelectedPaidTariffOption={setSelectedPaidTariffOption}
            selectedPlan={selectedPlan}
            showModal={showModal}
          />}
      </div>
      <CheckoutModal
        isLoading={isGetTransactionIdLoading}
        transactionIdProps={
          visibleModalPaymentUpdates ? transactionId : undefined
        }
        selectedPlan={selectedPlan}
        discountCodes={discountCodes}
        visible={visible}
        onClose={() => {
          setVisible(false);
          setVisibleModalPaymentUpdates(false);
        }}
        onComplete={checkoutModalComplete}
      />
      <SubscriptionSettingsModal
        visible={isShowSubscriptionSettingsModal}
        disableUpdate={disabledUpdate}
        disableCancel={disabledCancel}
        disableRetry={disableRetry}
        onClose={() => {
          setIsShowSubscriptionSettingsModal(false);
        }}
        onCancelSubscription={async () => {
          cancelSubscription().then(() =>
            startPollingCurrentSubscription("cancellation")
          );
        }}
        onUpdateSubscription={async () => {
          await getTransactionId().unwrap();
          showModal();
          setVisibleModalPaymentUpdates(true);
        }}
      />
    </>
  );
};
export default injectIntl(Dashboard);
