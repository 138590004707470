import { useEffect } from "react";
import { useGetCurrentSubscriptionQuery, useGetPlanByIdMutation, useGetProfileQuery } from "../api/reg-service.api";

const useGetPlanFromSubscriptions = () => {
  const [getPlanById, { data: currentPlanFromSubscription, error, isLoading }] = useGetPlanByIdMutation();
  const { data: profile } = useGetProfileQuery();
  const landingId = profile?.landingId || ""
  const { data: subscription } = useGetCurrentSubscriptionQuery();
  const planId = subscription?.planId

  useEffect(() => {
    if (planId) {
      getPlanById({ landingId: +landingId, planId: planId })
    }
  }, [planId, getPlanById, landingId]);

  return { currentPlanFromSubscription, error, isLoading };
};



export default useGetPlanFromSubscriptions;