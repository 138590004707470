import { FC, useMemo } from "react";
import { injectIntl } from "react-intl";
import { IBaseComponentProps } from "../../types";
import "./RequestPerSecond.sass";
import styles from "../../ui-kit/components/Card/Card.module.sass";
import cn from "classnames";
import RequestPerSecondArc from "../RequestPerSecondArc/RequestPerSecondArc";
import question from "../../resources/images/question.svg";
import { useGetRequestPerPeriodQuery } from "../../api/reg-service.api";
import useLimits from "../../hooks/useLimits";

type TProps = {} & IBaseComponentProps;

const RequestPerSecond: FC<TProps> = ({ className }) => {
  const { rps } = useLimits();

  const { data: requestsPerMinute } = useGetRequestPerPeriodQuery(
    {
      period: "minute",
    },
    {
      pollingInterval: 60000,
    }
  );
  // const legend = [
  //   {
  //     title: "Requests",
  //     color: "#007AFF",
  //   },
  //   {
  //     title: "Your limit: 1.4M  / Day",
  //     color: "#FF3B30",
  //   },
  // ];

  const requestPerSecond = useMemo(() => {
    if (!requestsPerMinute) return 0;
    return requestsPerMinute[0]
      ? Math.ceil(requestsPerMinute[0].requests / 60)
      : 0;
  }, [requestsPerMinute]);

  return (
    <div className={` ${className} request-per-second`}>
      <div className={cn(styles.head)}>
        <div className={cn("title-blue", styles.title)}>
          Requests Per Second
        </div>
      </div>
      <RequestPerSecondArc
        currentRps={Math.sign(requestPerSecond) === 1 ? requestPerSecond : 0}
        maxRps={rps}
        minRps={0}
      />
      <div className="request-per-second__info_block">
        <div className="request-per-second__info">
          <img className="request-per-second__info_img" src={question} alt="" />
          Med: {requestPerSecond}
        </div>
        <div className="request-per-second__info">
          <img className="request-per-second__info_img" src={question} alt="" />
          Max : {requestPerSecond}
        </div>
      </div>
      <div className="request-per-second__legend">
        <div className="request-per-second__indicator">
          <div
            className="request-per-second__color"
            style={{ backgroundColor: "rgba(131, 191, 110, 1)" }}
          ></div>
          Your limit: {rps} RPS
        </div>
      </div>
    </div>
  );
};
export default injectIntl(RequestPerSecond);
