import React, { FC, useMemo } from "react";
import styles from "./ProductActivity.module.sass";

import Card from "../../../components/Card";
import Item from "./Item";
import { IBaseComponentProps } from "../../../../types";
import { injectIntl } from "react-intl";
import { useGetApiKeysQuery } from "../../../../api/reg-service.api";

type TProps = {

} & IBaseComponentProps

const ProductActivity: FC<TProps> = ({ className }) => {
  const { data: apiKeys, isLoading } = useGetApiKeysQuery();

  const items = useMemo(() => {
    return [
      {
        title: "API Keys::",
        products: {
          counter: apiKeys?.length,
          color: "#B5E4CA",
          text: "of 1 API Keys Used",
        },

      },
      {
        title: "Blocked IP:",
        products: {
          counter: 0,
          color: "rgba(255, 106, 85, 1)",
          text: "Addresses Blocked",
        },
      },
    ]
  }, [apiKeys?.length])

  if (isLoading) return null

  return (

    <Card
      className={`${styles.card} ${className}`}
      title="Additional info"
      classTitle="title-green"
      classCardHead={undefined}
      head={undefined}
    >
      <div className={styles.table}>
        {items.map((x, index) => (
          <div className={styles.row} key={index}>
            <div className={styles.col}>
              {/* <div className={styles.label}>Week</div> */}
              {x.title}
            </div>
            <div className={styles.col}>
              {/* <div className={styles.label}>Products</div> */}
              <Item className={styles.item} item={x.products} />
              <span className={styles.text}>{x.products.text}</span>
            </div>
          </div>
        ))}
      </div>
      {/* <div className={styles.nav}>
        {intervals.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: x === activeTab,
            })}
            onClick={() => setActiveTab(x)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div> */}
    </Card>
  );
};

export default injectIntl(ProductActivity);
