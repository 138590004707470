import React, { useMemo, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Chart from "./Chart";
import { TPeriod } from "../../../../api/types/get-requests-per-period";
import { useGetRequestPerPeriodQuery } from "../../../../api/reg-service.api";
// import useDataTransferLimit from "../../../../hooks/useDataTransferLimits";
import BigNumber from "bignumber.js";

// const intervals = ["Last 28 days", "Last 14 days", "Last 7 days"];
const intervals = [
  "24 Hours", // by hour
  "30 Days", // by day
  "12 Months", // by month
];

const mapOptionToPeriod: Record<string, TPeriod> = {
  "24 Hours": "hour",
  "30 Days": "day",
  "12 Months": "month",
};

const mapPeriodToOption: Record<TPeriod, string> = {
  hour: "24 Hours",
  day: "30 Days",
  month: "12 Months",
  minute: "60 Minutes",
};

interface IProps {
  className?: string;
}

const formatterMap: Record<TPeriod, (date: string) => string | number> = {
  hour: (date) => new Date(date).getHours(),
  day: (date) =>
    new Date(date).toLocaleDateString("en", {
      month: "short",
      day: "2-digit",
    }),
  minute: (date) => new Date(date).getMinutes(),
  month: (date) =>
    new Date(date).toLocaleDateString("en", {
      month: "short",
    }),
};

const formatterBytesMap = {
  mb: (bytes: number) => new BigNumber(bytes).times(1e-6),
  gb: (bytes: number) => new BigNumber(bytes).times(1e-9),
  tb: (bytes: number) => new BigNumber(bytes).times(1e-12),
};

const Overview: React.FC<IProps> = ({ className }) => {
  // const [sorting, setSorting] = useState(intervals[0]);
  const [period, setPeriod] = useState<TPeriod>("hour");
  const { data: requestPerPeriod, isLoading: isLoadingRequestsPerPeriod } =
    useGetRequestPerPeriodQuery(
      {
        period,
      },
      {
        pollingInterval: 60000,
      }
    );
  // const { dayLimit, monthLimit } = useDataTransferLimit();

  const limit = useMemo(() => {
    let _limit = 0;
    // switch (period) {
    //   case "day":
    //     _limit = monthLimit;
    //     break;
    //   case "hour":
    //     _limit = dayLimit;
    //     break;
    //   case "month":
    //     _limit = 0;
    //     break;
    // }

    return _limit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestPerPeriod]);

  // const adaptiveData = useMemo(() => {}, []);

  const data = useMemo(() => {
    if (!requestPerPeriod) return [];
    const reversedData = [...requestPerPeriod].reverse();
    let cumulativeGb = new BigNumber(0);
    return [
      ...reversedData.map((el) => {
        cumulativeGb = cumulativeGb.plus(formatterBytesMap.gb(el.bytes));
        return {
          name: formatterMap[period](el.date),
          gb: cumulativeGb.toNumber(),
        };
      }),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestPerPeriod]);

  const legend =
    !data.length && !isLoadingRequestsPerPeriod
      ? []
      : [
          {
            title: "Traffic",
            color: "#007AFF",
          },
          // {
          //   title: `Your Limit: ${new BigNumber(monthLimit || 0)
          //     .times(1e-9)
          //     .toString()}GB / Mo`,
          //   color: "#FF3B30",
          // },
        ];
  return (
    <Card
      className={cn(styles.card, className)}
      title="Total data transferred"
      classTitle={cn("title-red", styles.cardTitle)}
      classCardHead={styles.cardHead}
      head={
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={mapPeriodToOption[period]}
          setValue={(value) => {
            const nextPeriod = mapOptionToPeriod[value];
            if (nextPeriod) {
              setPeriod(nextPeriod);
            }
          }}
          options={intervals}
          small
        />
      }
    >
      <div className={styles.overview}>
        <div className={styles.details}>
          {/* <div className={cn("h4", styles.title)}>1,509 customers</div> */}
          {/* <div className={styles.line}>
            <Balance className={styles.balance} value="37.8" background /> vs.
            Sep 8, 2021
          </div> */}
        </div>
        <Chart
          data={data}
          limit={limit}
          isLoading={isLoadingRequestsPerPeriod}
        />
        {/* <Users className={styles.users} /> */}
        <div className={styles.legend}>
          {legend.map((x, index) => (
            <div className={styles.indicator} key={index}>
              <div
                className={styles.color}
                style={{ backgroundColor: x.color }}
              ></div>
              {x.title}
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default Overview;
