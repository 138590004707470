import { useMemo } from "react";
import { useGetCurrentPlanQuery } from "../api/reg-service.api";

const useLimits = () => {
  const { data: currentPlan } = useGetCurrentPlanQuery();

  const hourLimit = useMemo(() => {
    if (!currentPlan) return 0;
    return currentPlan.rps * 60 * 60;
  }, [currentPlan]);

  const dayLimit = useMemo(() => {
    if (!hourLimit) return 0;
    return hourLimit * 24;
  }, [hourLimit]);

  const monthLimit = useMemo(() => {
    if (!dayLimit) return 0;
    return dayLimit * 30;
  }, [dayLimit]);

  return {
    rps: currentPlan?.rps || 0,
    hourLimit,
    dayLimit,
    monthLimit,
  };
};

export default useLimits;
