import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import { ROUTE_PATH } from "../../../../constants";
import { useLogoutMutation } from "../../../../api/reg-service.api";

interface IProps {
  className?: string;
}

interface IItem {
  title: string;
  url?: string;
  color?: string;
  icon?: string;
  onClick?: VoidFunction;
}

const User: React.FC<IProps> = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const { pathname } = useLocation();
  const [logout] = useLogoutMutation();

  const items: {
    menu: IItem[];
  }[] = [
    {
      menu: [
        {
          title: "Edit profile",
          url: ROUTE_PATH.PROFILE,
        },
      ],
    },
    {
      menu: [
        {
          title: "Log out",
          onClick: () => {
            logout();
          },
        },
      ],
    },
  ];

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.user, className, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <img src="/images/content/avatar.svg" alt="Avatar" />
        </button>
        <div className={styles.body}>
          {items.map((item, index) => (
            <div className={styles.menu} key={index}>
              {item.menu.map((x, index) =>
                x.url ? (
                  <NavLink
                    className={cn(styles.item, {
                      [styles.color]: x.color,
                      [styles.active]: pathname === x.url,
                    })}
                    to={x.url}
                    onClick={() => setVisible(false)}
                    key={index}
                  >
                    {x.icon && <Icon name={x.icon} size="24" />}
                    {x.title}
                  </NavLink>
                ) : (
                  <button
                    className={styles.item}
                    onClick={() => {
                      if (x.onClick) x.onClick();
                      setVisible(false);
                    }}
                    key={index}
                  >
                    {x.title}
                  </button>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
