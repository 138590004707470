import { useMemo } from "react";
import usePlanType, { EPlanType } from "./usePlanType";
import { TSubscription } from "../entities/subscription";
import { checkAvailabilityTs, mapCycleToMs } from "../utils/utils";
import { PLAN_TS_STORAGE_KEY, SUBSCRIPTION_TS_STORAGE_KEY } from "../constants";
import { IGetCurrentPlanResponse } from "../api/types/get-current-plan";
import { IGetCurrentSubscriptionResponse } from "../api/types/get-subscription";
import { selectPending } from "../reducers/pendings.slice";
import { useSelector } from "react-redux";
import { TPlan } from "../entities/plan";
import { MessageDescriptor } from "../utils/formatMessage";
import {
  createCancelledView,
  createDefaultView,
  createFallbackView,
  createTailoredView,
  isCancelledSubscription,
  isDefaultPlan,
  isResponseFirstLoading,
  isTailoredPlan,
} from "../utils/subscriptions";

export const HOME_PAGE_BUTTON_SUBSCRIBE = { id: "home-page.button-subscribe" };
const HOME_PAGE_YOUR_ACTIVE_SUBSCRIPTION = {
  id: "plans-list.your-active-subscription",
};
// const SUBSCRIPTION_YOU_HAVE_EXCEEDED = { id: "home-page.you-have-exceeded" };
// const getDescriptorForCancelPlan: (date?: string) => MessageDescriptor = (
//   date
// ) => ({ id: "home-page.your-cancelled-plan", values: { date } });
// const SUBSCRIPTION_PAYMENT_FAILED = { id: "home-page.payment-failed" };

export function getExpireSubscriptionDate(currentSubscription: TSubscription) {
  const billingCycle = currentSubscription.billingCycle;
  if (!billingCycle) return 0;
  const fromTime = currentSubscription.firstBilledAt
    ? new Date(currentSubscription.firstBilledAt).getTime()
    : 0;
  const nextDate =
    fromTime +
    mapCycleToMs[billingCycle.interval] * billingCycle.frequency * 1000;
  return nextDate;
}

const SUBSCRIPTION_PENDING_PAYMENT = { id: "home-page.payment-pending" };
const SUBSCRIPTION_ERROR_PAYMENT = { id: "home-page.payment-error" };
const SUBSCRIPTION_ERROR_PAYMENT_OVERDUE = { id: "home-page.payment-overdue" };

function checkPendingPayment(
  planPendingData:
    | ({ ts: number; trigger: string } & Record<string, any>)
    | undefined
) {
  return (
    planPendingData &&
    planPendingData.trigger === "creation" &&
    checkAvailabilityTs(planPendingData.ts, {
      frequency: 10,
      interval: "minute",
    })
  );
}

function checkTooLongPaymentPending(
  planPendingData:
    | ({ ts: number; trigger: string } & Record<string, any>)
    | undefined
) {
  return (
    planPendingData &&
    planPendingData.trigger === "creation" &&
    checkAvailabilityTs(planPendingData.ts, {
      frequency: 1,
      interval: "day",
    })
  );
}

function useSubscriptionBadge(
  currentPlan: IGetCurrentPlanResponse | undefined,
  errorPlan: unknown,
  isLoadingPlan: boolean,
  currentSubscription: IGetCurrentSubscriptionResponse | undefined,
  errorSubscription: unknown,
  isLoadingSubscription: boolean
) {
  const planPendingData = useSelector(selectPending(PLAN_TS_STORAGE_KEY));
  const subscriptionPendingData = useSelector(
    selectPending(SUBSCRIPTION_TS_STORAGE_KEY)
  );
  const planType = usePlanType(currentPlan);
  return useMemo<
    | { type: "success" | "warning" | "error"; message: MessageDescriptor }
    | undefined
  >(() => {
    if (
      !currentPlan ||
      (!currentPlan && !errorPlan && isLoadingPlan) ||
      (!currentSubscription && !errorSubscription && isLoadingSubscription)
    )
      return undefined;

    if (
      (planType === EPlanType.STANDARD ||
        planType === EPlanType.NEW_STANDARD ||
        planType === EPlanType.TAILORED) &&
      currentSubscription &&
      (currentSubscription.status === "canceled" ||
        !currentSubscription.nextBilledAt)
    ) {
      const nextPaymentMs = currentPlan.validUntil
        ? new Date(currentPlan.validUntil).getTime()
        : getExpireSubscriptionDate(currentSubscription);
      const alreadyCanceled = new Date() > new Date(nextPaymentMs);

      if (alreadyCanceled) return undefined;

      return {
        type: "warning",
        message: {
          id: "home-page.your-cancelled-plan",
          params: {
            date: new Date(nextPaymentMs).toLocaleDateString(),
          },
        },
      };
    }

    if (
      checkPendingPayment(planPendingData) ||
      checkPendingPayment(subscriptionPendingData)
    ) {
      return {
        type: "warning",
        message: SUBSCRIPTION_PENDING_PAYMENT,
      };
    }

    if (
      checkTooLongPaymentPending(planPendingData) ||
      checkTooLongPaymentPending(subscriptionPendingData)
    ) {
      return {
        type: "error",
        message: SUBSCRIPTION_ERROR_PAYMENT,
      };
    }

    if (
      currentSubscription &&
      currentSubscription.status === "past_due" &&
      planType !== EPlanType.TAILORED
    ) {
      const nextBilledAt = new Date(
        currentSubscription?.nextBilledAt || ""
      ).toLocaleDateString();
      return {
        type: "error",
        message: {
          id: SUBSCRIPTION_ERROR_PAYMENT_OVERDUE.id,
          params: {
            date: nextBilledAt,
          },
        },
      };
    }
    // else if (
    //   !currentSubscription &&
    //   planType !== EPlanType.DEFAULT &&
    //   currentPlan &&
    //   currentPlan.validUntil
    // ) {
    //   return {
    //     type: "warning",
    //     message: getDescriptorForCancelPlan(
    //       new Date(currentPlan.validUntil).toLocaleDateString()
    //     ),
    //   };
    // }
    return undefined;
  }, [
    currentPlan,
    currentSubscription,
    errorPlan,
    errorSubscription,
    isLoadingPlan,
    isLoadingSubscription,
    planPendingData,
    planType,
    subscriptionPendingData,
  ]);
}

const useSubscriptionDetails = (
  currentPlanQuery: {
    data?: IGetCurrentPlanResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  currentSubscriptionQuery: {
    data?: IGetCurrentSubscriptionResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  selectedPlan: string,
  currentPlanFromSubscription?: TPlan
) => {
  const planPendingData = useSelector(selectPending(PLAN_TS_STORAGE_KEY));
  const subscriptionPendingData = useSelector(
    selectPending(SUBSCRIPTION_TS_STORAGE_KEY)
  );

  const planType = usePlanType(currentPlanQuery.data);

  const badge = useSubscriptionBadge(
    currentPlanQuery.data,
    currentPlanQuery.error,
    currentPlanQuery.isLoading,
    currentSubscriptionQuery.data,
    currentSubscriptionQuery.error,
    currentSubscriptionQuery.isLoading
  );

  const subscription = useMemo<
    Omit<TSubscription, "discount"> | undefined
  >(() => {
    if (
      !currentPlanQuery.data ||
      isResponseFirstLoading(currentPlanQuery) ||
      isResponseFirstLoading(currentSubscriptionQuery)
    )
      return undefined;
    else if (isDefaultPlan(planType)) {
      return createDefaultView(
        currentSubscriptionQuery,
        currentPlanQuery,
        currentPlanFromSubscription
      );
    } else if (
      isCancelledSubscription(currentPlanQuery, currentSubscriptionQuery)
    ) {
      return createCancelledView(
        currentPlanQuery,
        currentSubscriptionQuery,
        currentPlanFromSubscription
      );
    } else if (isTailoredPlan(planType)) {
      return createTailoredView(currentPlanQuery, currentPlanFromSubscription);
    }

    // Add case for tailored and another subscription

    return createFallbackView(
      currentSubscriptionQuery,
      currentPlanFromSubscription
    );
  }, [
    currentPlanQuery,
    currentSubscriptionQuery,
    planType,
    currentPlanFromSubscription,
  ]);

  // const subscribeIsAvailable = useMemo(() => {
  //   if (subscription?.status === "canceled") return true;
  //   else if (
  //     planType === EPlanType.DEFAULT &&
  //     (planPendingData || subscriptionPendingData)
  //   )
  //     return true;
  //   else if (
  //     checkTooLongPaymentPending(planPendingData) ||
  //     checkTooLongPaymentPending(subscriptionPendingData)
  //   )
  //     return true;
  //   return false;
  // }, [
  //   planPendingData,
  //   planType,
  //   subscription?.status,
  //   subscriptionPendingData,
  // ]);
  const standardButtonProps = useMemo(() => {
    if (
      planType === EPlanType.DEFAULT &&
      (!currentSubscriptionQuery.data ||
        currentSubscriptionQuery.data.status === "canceled") &&
      !planPendingData &&
      !subscriptionPendingData
    ) {
      // ok отмененная
      return {
        disabled: false,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      };
    } else if (
      currentSubscriptionQuery.data &&
      currentSubscriptionQuery.data.status !== "canceled" &&
      planType !== EPlanType.DEFAULT &&
      planType !== EPlanType.TAILORED &&
      selectedPlan === currentPlanQuery.data?.name
    ) {
      // ok !! платная
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_YOUR_ACTIVE_SUBSCRIPTION,
      };
    } else if (
      currentSubscriptionQuery.data &&
      currentSubscriptionQuery.data.status !== "canceled" &&
      planType !== EPlanType.DEFAULT &&
      planType !== EPlanType.TAILORED &&
      selectedPlan !== currentPlanQuery.data?.name
    ) {
      // ok !! платная
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      };
    } else if (
      checkPendingPayment(planPendingData) ||
      checkPendingPayment(subscriptionPendingData)
    ) {
      // ok
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      };
    } else if (
      checkTooLongPaymentPending(planPendingData) ||
      checkTooLongPaymentPending(subscriptionPendingData)
    ) {
      // ok
      return {
        disabled: false,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      };
    } else if (
      currentSubscriptionQuery.data?.status === "past_due" &&
      selectedPlan === currentPlanQuery.data?.name
    ) {
      // ok
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_YOUR_ACTIVE_SUBSCRIPTION,
      };
    } else if (
      currentSubscriptionQuery.data?.status === "past_due" &&
      selectedPlan !== currentPlanQuery.data?.name
    ) {
      // ok
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      };
    } else if (
      planType === EPlanType.DEFAULT &&
      currentSubscriptionQuery.data &&
      currentSubscriptionQuery.data.status !== "canceled"
    ) {
      // ok
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      };
    } else if (
      currentSubscriptionQuery?.data?.status === "canceled" &&
      selectedPlan === currentPlanQuery.data?.name
    ) {
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_YOUR_ACTIVE_SUBSCRIPTION,
      };
    } else if (
      currentSubscriptionQuery?.data?.status === "canceled" &&
      selectedPlan !== currentPlanQuery.data?.name
    ) {
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      };
    } else if (planType === EPlanType.TAILORED)
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      }; // ok    return undefined;
  }, [
    planPendingData,
    planType,
    currentSubscriptionQuery.data,
    subscriptionPendingData,
    selectedPlan,
    currentPlanQuery.data?.name,
  ]);

  /*
    DEFAULT:
    disabled false
    button subscribe

    Have active subscription:
    disabled: true
    button: Your active plan

    Pending subscription:
    disabled: true
    button: subscribe

    Over due pending subscription:
    disabled: false
    button: subscribe

    Over due:
    disabled: true
    button: Your active plan

    Beginner and Active subscription:
    disabled: true
    button: subscribe

    Tailored:
    button: undefined
    disabled: true
  */
  return {
    subscription,
    badge,
    standardButtonProps,
  };
};

export default useSubscriptionDetails;
