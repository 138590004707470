import { injectIntl } from "react-intl";
import cn from "classnames";
import { IBaseComponentProps } from "../../types";
import "./CheckoutModal.sass";
// import buttonClose from "../../resources/images/button-close.png";
// import { Modal } from "antd";
import PaddleWidget from "../PaddleWidget";
import { Modal } from "antd";
import { TPlan } from "../../entities/plan";
import { TDiscountCode } from "../../entities/discountCode";

interface IProps extends IBaseComponentProps {
  visible: boolean;
  onClose: VoidFunction;
  onComplete: VoidFunction;
  selectedPlan: TPlan;
  transactionIdProps?: string
  isLoading: boolean
  discountCodes?: TDiscountCode[];
}

const CheckoutModal: React.FC<IProps> = ({
  className,
  visible,
  onClose,
  onComplete,
  selectedPlan,
  transactionIdProps,
  isLoading,
  discountCodes,
}) => {
  return (
    <Modal
      className={cn("checkout-modal", className)}
      transitionName={""}
      open={visible}
      closeIcon={null}
      onCancel={onClose}
      destroyOnClose
      footer={[]}
    >
      <PaddleWidget
        isLoading={isLoading}
        transactionIdProps={transactionIdProps}
        selectedPlan={selectedPlan}
        discountCodes={discountCodes}
        className="checkout-modal__widget"
        onClose={onClose}
        onComplete={onComplete}
      />
    </Modal>
  );
};

export default injectIntl(CheckoutModal);
