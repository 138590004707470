import React from "react";
import styles from "./Chart.module.sass";
import cn from 'classnames'
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
    AreaChart,
    Area,
} from "recharts";
import useDarkMode from "../../../../../hooks/useDarkMode";

const Chart = ({ data, limit, isLoading }) => {
    const darkMode = useDarkMode(true, { storageKey: "" });
    return (
        <div className={styles.chart}>
        {!data.length && !isLoading ? (
          <>
            <img
              className={styles.stub}
              alt="stub"
              src="/images/content/stub-2.svg"
            />
            <div className={cn(styles.titleStub)}>No data available</div>
          </>
        ) : null}
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid
                        strokeDasharray="none"
                        stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
                        vertical={false}
                    />
                    <XAxis
                        dataKey="name"
                        axisLine={false}
                        tickLine={false}
                        tick={{
                            fontSize: 12,
                            fontWeight: "500",
                            fill: "#9A9FA5",
                        }}
                        padding={{ left: 10 }}
                    />
                    <YAxis
                        axisLine={false}
                        tickLine={false}
                        tick={{
                            fontSize: 12,
                            fontWeight: "500",
                            fill: "#9A9FA5",
                        }}
                    />
                    <Tooltip
                        contentStyle={{
                            backgroundColor: "#272B30",
                            borderColor: "rgba(255, 255, 255, 0.12)",
                            borderRadius: 8,
                            boxShadow:
                                "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
                        }}
                        labelStyle={{
                            fontSize: 12,
                            fontWeight: "500",
                            color: "#fff",
                        }}
                        itemStyle={{
                            padding: 0,
                            textTransform: "capitalize",
                            fontSize: 12,
                            fontWeight: "600",
                            color: "#fff",
                        }}
                    />
                    <Area
                        type="monotone"
                        dataKey="gb"
                        dot={false}
                        strokeWidth={4}
                        stroke="#2A85FF"
                    />
                    {limit && <ReferenceLine
                      y={limit}
                      stroke="#FF3B30"
                      strokeDasharray="5 5"
                      ifOverflow="visible"
                      strokeWidth={2}
                    />}
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default Chart;
