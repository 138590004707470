import React, { FC, useMemo } from "react";
import styles from "./TopCountries.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import nextBillIcon from "../../../../resources/images/next-bill.svg";
import dueDateIcon from "../../../../resources/images/due-date.svg";
import planIcon from "../../../../resources/images/plan.svg";
import termIcon from "../../../../resources/images/term.svg";
import startDateIcon from "../../../../resources/images/start-date.svg";
import endDateIcon from "../../../../resources/images/end-date.svg";
import statusIcon from "../../../../resources/images/status.svg";
import { IBaseComponentProps } from "../../../../types";
import { TSubscription } from "../../../../entities/subscription";
import { injectIntl } from "react-intl";
import { TPlan } from "../../../../entities/plan";
import usePlanType, { EPlanType } from "../../../../hooks/usePlanType";
import formatMessage from "../../../../utils/formatMessage";
import {
  useGetCurrentPlanQuery,
  useGetCurrentSubscriptionQuery,
} from "../../../../api/reg-service.api";
import useGetPlanFromSubscriptions from "../../../../hooks/useGetPlanFromSubscriptions";
const HOME_PAGE_FREE = { id: "plans-list.free" };
const HOME_PAGE_PRO = { id: "home-page.pro" };
export const HOME_PAGE_TAILORED = { id: "plans-list.tailored" };
const PLUG = { id: "plug" };

type TProps = {
  subscription: Omit<TSubscription, "discount"> & { endDate?: number };
  nextBilledPrice: string;
  plan: TPlan;
} & IBaseComponentProps;

const statusMap: Record<TSubscription["status"], string> = {
  active: "Active",
  canceled: "Canceled",
  past_due: "Overdue",
  paused: "Paused",
  trialing: "Trialing",
};

const mapPlanTypeToName: Record<EPlanType, { id: string }> = {
  [EPlanType.DEFAULT]: HOME_PAGE_FREE,
  [EPlanType.STANDARD]: HOME_PAGE_PRO,
  [EPlanType.TAILORED]: HOME_PAGE_TAILORED,
  [EPlanType.UNKNOWN]: HOME_PAGE_FREE,
  [EPlanType.NEW_STANDARD]: PLUG,
};

const TopCountries: FC<TProps> = ({
  className,
  subscription,
  nextBilledPrice,
  plan,
  intl,
}) => {
  const planType = usePlanType(plan);
  const planName = useMemo(() => {
    if (subscription.status === "past_due" && planType === EPlanType.TAILORED) {
      return formatMessage(intl, mapPlanTypeToName[EPlanType.TAILORED]);
    }

    if (subscription.status === "past_due") {
      return subscription.planFromSubscription?.name;
    }

    if (planType === EPlanType.NEW_STANDARD) {
      return plan?.name;
    }

    return formatMessage(intl, mapPlanTypeToName[planType]);
  }, [
    intl,
    plan?.name,
    planType,
    subscription.planFromSubscription?.name,
    subscription.status,
  ]);

  const subscriptionData = useMemo(() => {
    return [
      {
        title: "Next bill:",
        img: nextBillIcon,
        value: plan?.price !== undefined ? `${nextBilledPrice}$` : "-",
      },
      {
        title: "Due Date:",
        img: dueDateIcon,
        value:
          subscription.nextBilledAt && subscription.status !== "past_due"
            ? new Date(subscription.nextBilledAt).toLocaleDateString()
            : "-",
      },
      {
        title: "Plan:",
        img: planIcon,
        value: planName,
      },
      {
        doNotShow:
          (!plan.isDefault && subscription.billingCycle) ||
          (plan.isDefault && subscription.status === "past_due")
            ? false
            : true,
        title: "Term",
        img: termIcon,
        value: "30 days",
        //  subscription.billingCycle && `${subscription.billingCycle.frequency} ${subscription.billingCycle.interval
        // }${subscription.billingCycle.frequency > 1 ? "s" : ""}`
      },
      {
        title: "Start Date",
        img: startDateIcon,
        value: subscription.firstBilledAt
          ? new Date(subscription.firstBilledAt).toLocaleDateString()
          : "-",
      },
      {
        doNotShow: !subscription.endDate,
        title: "End Date",
        img: endDateIcon,
        value: subscription.endDate
          ? new Date(subscription.endDate).toLocaleDateString()
          : "-",
      },
      {
        title: "Status",
        img: statusIcon,
        value: statusMap[subscription.status],
      },
    ];
  }, [
    nextBilledPrice,
    plan.isDefault,
    plan?.price,
    planName,
    subscription.billingCycle,
    subscription.endDate,
    subscription.firstBilledAt,
    subscription.nextBilledAt,
    subscription.status,
  ]);

  const { isLoading: isGetCurrentSubscriptionLoading } =
    useGetCurrentSubscriptionQuery();
  const { isLoading: isGetCurrentPlanLoading } = useGetCurrentPlanQuery();
  const { isLoading: isGetPlanFromSubscriptionLoading } =
    useGetPlanFromSubscriptions();

  if (
    isGetCurrentPlanLoading ||
    isGetCurrentSubscriptionLoading ||
    isGetPlanFromSubscriptionLoading
  )
    return null;

  return (
    <Card
      className={cn(styles.card, className)}
      title="Subscription"
      classTitle="title-blue"
      classCardHead={undefined}
      head={undefined}
    >
      <div className={`${styles.screen} ${styles.screenRotate}`}></div>
      <div className={styles.countries}>
        {subscriptionData.map((el, index) => {
          // eslint-disable-next-line array-callback-return
          if (el.doNotShow) return;
          return (
            <div className={styles.item} key={index}>
              <div className={styles.img}>
                <img src={el.img} alt="" />
              </div>
              <div className={styles.title}>{el.title}</div>
              <div className={styles.price}>{el.value}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.screen}></div>
    </Card>
  );
};

export default injectIntl(TopCountries);
