import { FC, useMemo } from "react";
import "./RequestPerSecondArc.sass";
import { IBaseComponentProps } from "../../types";
import { injectIntl } from "react-intl";

type TProps = {
  maxRps: number;
  minRps: number;
  currentRps: number;
} & IBaseComponentProps;

const RequestPerSecondArc: FC<TProps> = ({
  className,
  currentRps,
  maxRps,
  minRps,
}) => {
  const step = 190 / maxRps;
  const rps = currentRps > maxRps ? maxRps : currentRps;
  const arrowPosition = step * rps;

  const isExceedingLimit = useMemo(() => {
    const present = maxRps / 100;
    const result = currentRps / present;

    if (result > 95) return true;
    return false;
  }, [maxRps, currentRps]);

  return (
    <div className={`${className} request-per-second-arc`}>
      <svg
        width="276"
        height="141"
        viewBox="0 0 276 141"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M276 138.376C276 101.776 261.461 66.6753 235.581 40.7952C209.701 14.9152 174.6 0.375979 138 0.375977C101.4 0.375974 66.2993 14.9152 40.4193 40.7952C14.5393 66.6752 5.71363e-06 101.776 1.87204e-07 138.376H34.5C34.5 110.926 45.4044 84.6004 64.8145 65.1904C84.2245 45.7804 110.55 34.876 138 34.876C165.45 34.876 191.776 45.7804 211.186 65.1904C230.596 84.6004 241.5 110.926 241.5 138.376H276Z"
          fill="#158531"
        />
        <path
          d="M71.1641 17.6409C49.5405 29.6111 31.5286 47.169 19.0103 68.4799C6.492 89.7909 -0.0732228 114.073 0.000616037 138.788L34.5005 138.685C34.4451 120.149 39.369 101.937 48.7577 85.954C58.1465 69.9708 71.6554 56.8023 87.8731 47.8247L71.1641 17.6409Z"
          fill="#76FF99"
        />
        <path
          d="M207 18.8645C186.074 6.78263 162.342 0.407271 138.178 0.376091C114.014 0.344912 90.2661 6.65901 69.3087 18.6868L86.4815 48.6091C102.2 39.5882 120.011 34.8527 138.134 34.8761C156.256 34.8994 174.055 39.681 189.75 48.7423L207 18.8645Z"
          fill="#38C55B"
        />
      </svg>
      <div className="request-per-second-arc__value">{currentRps}</div>
      <div
        style={{ transform: `rotate(${arrowPosition - 5}deg)` }}
        className={`${
          isExceedingLimit && "request-per-second-arc__exceeding-limit"
        } request-per-second-arc__arrow`}
      ></div>
      <div className="request-per-second-arc__values">
        <p>{minRps}</p> <p>{maxRps}</p>
      </div>
    </div>
  );
};

export default injectIntl(RequestPerSecondArc);
